<template>
  <el-dialog
    v-model="model"
    width="30%"
    align-center
    destroy-on-close
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-openApi-integration-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-openApi-integration-dialog-header__title">
        {{ t('title.openAPIIntegration') }}
      </h4>
    </template>
    <div v-loading="loading" class="elv-openApi-integration-dialog-content">
      <div class="elv-openApi-integration-dialog-content-header">
        <p>
          {{ t('common.totalKeys') }}: <span>{{ openApiAccessList.length }}</span>
        </p>
        <div class="elv-openApi-integration-dialog-content-header__operation">
          <div class="elv-openApi-integration-operation__document" @click="onJumpDocs">
            <svgIcon name="document" width="14" height="14" fill="#838D95" />{{ t('button.APIDocument') }}
          </div>
          <elv-button height="32" width="81" round plain type="primary" @click="onCreateOpenAPI">
            <SvgIcon name="source-add" width="16" height="16" />{{ t('button.create') }}</elv-button
          >
        </div>
      </div>

      <el-table
        ref="tableListRef"
        :data="openApiAccessList"
        border
        stripe
        class="elv-openApi-integration-table"
        header-cell-class-name="elv-openApi-integration-table-header__cell"
        header-row-class-name="elv-openApi-integration-table-header"
        row-class-name="elv-openApi-integration-table-row"
        cell-class-name="elv-openApi-integration-table-row__cell"
      >
        <el-table-column :label="t('common.name')" width="130">
          <template #default="{ row }">
            <TextCell :text="row.name" font-family="Plus Jakarta Sans" />
          </template>
        </el-table-column>
        <el-table-column :label="t('report.key')" width="130">
          <template #default="{ row }">
            <div class="elv-openApi-integration-table-row__cell-key">
              {{ middleEllipsis(row.apiKey, 6) }}
              <img src="@/assets/img/copy.png" alt="copy" @click="onClickedCopy(row.apiKey)" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.secret')" width="130">
          <template #default="{ row }">
            <div class="elv-openApi-integration-table-row__cell-key">
              {{ middleEllipsis(row.apiSecret, 6) }}
              <img src="@/assets/img/copy.png" alt="copy" @click="onClickedCopy(row.apiSecret)" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('common.created')" width="140">
          <template #default="{ row }">
            <TextCell
              :text="dayjs.tz(row.createdAt, entityStore.entityDetail?.timezone).format('YYYY/MM/DD HH:mm:ss')"
            />
          </template>
        </el-table-column>
        <el-table-column label="" align="center">
          <template #default="{ row }">
            <div class="elv-openApi-integration-table-row__cell-button">
              <SvgIcon name="sources-edit" width="18" height="18" @click="onEdit(row)" />
              <SvgIcon name="sources-delete" width="18" height="18" @click="onDelete(row)" />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <OpenApiKeyDialog
      v-model="showOpenApiKeyDialog"
      :current-data="currentData"
      :type="openApiKeyDialogType"
      @onCheckDialog="showOpenApiKeyDialog = false"
      @onResetDataList="fetchOpenApiAccessList"
    />

    <ElvMessageBox
      ref="deleteMessageBoxRef"
      :show-cancel-button="true"
      :confirm-button-text="t('button.delete')"
      :cancel-button-text="t('button.cancel')"
      :title="t('title.deleteAPIKeySecret')"
      :loading="deleteLoading"
      class="elv-openApi-integration-confirm-delete"
      @onConfirmEvent="onConfirmDeleteApi"
      @onCancelEvent="onCancelDeleteApi"
    >
      <template #content>
        <span
          >Are you sure you want to delete this API key/secret? Once deleted, any data integrations relying on this
          key/secret will cease to function. <br />Please ensure that this key/secret is no longer in use before
          proceeding.</span
        >
      </template>
    </ElvMessageBox>
  </el-dialog>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import timezone from 'dayjs/plugin/timezone'
import { middleEllipsis } from '@/lib/utils'
import BusinessDataApi from '@/api/BusinessDataApi'
import OpenApiKeyDialog from './OpenApiKeyDialog.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { OpenApiAccessIdType } from '#/BusinessDataTypes'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const { toClipboard } = useClipboard()

const entityStore = useEntityStore()
const model = defineModel<boolean>({ required: true })
const openApiAccessList = ref<OpenApiAccessIdType[]>([])
const loading = ref(false)
const currentData: any = ref({})
const deleteMessageBoxRef = ref()
const showOpenApiKeyDialog = ref(false)
const deleteLoading = ref(false)
const openApiKeyDialogType = ref('add')

// eslint-disable-next-line no-unused-vars
const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

// eslint-disable-next-line no-unused-vars
const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const fetchOpenApiAccessList = async () => {
  try {
    loading.value = true
    const { data } = await BusinessDataApi.getOpenApiAccessList(entityId.value)
    openApiAccessList.value = data
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

const onCreateOpenAPI = () => {
  openApiKeyDialogType.value = 'add'
  showOpenApiKeyDialog.value = true
}

const onCancelDeleteApi = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onConfirmDeleteApi = async () => {
  try {
    deleteLoading.value = true
    await BusinessDataApi.deleteOpenApiAccess(entityId.value, currentData.value?.openApiAccessId ?? '')
    onCancelDeleteApi()
    ElMessage.success(t('message.deleteSuccess'))
    fetchOpenApiAccessList()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

const onEdit = (row: any) => {
  currentData.value = row
  openApiKeyDialogType.value = 'edit'
  showOpenApiKeyDialog.value = true
}

const onDelete = (row: any) => {
  currentData.value = row
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}

const onJumpDocs = () => {
  window.open('https://docs.elven.com/chinese/development/tong-guo-openapi-huo-qu-ye-wu-shu-ju', '_blank')
}

watch(
  () => model.value,
  (value) => {
    if (value) {
      fetchOpenApiAccessList()
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-openApi-integration-dialog {
  width: 660px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-source-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-openApi-integration-dialog-content {
      width: 100%;
    }

    .elv-openApi-integration-dialog-content-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      p {
        color: #838d95;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
          margin-left: 8px;
          color: #0e0f11;
        }
      }

      .elv-openApi-integration-dialog-content-header__operation {
        display: flex;
        align-items: center;
      }

      .elv-openApi-integration-operation__document {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-right: 8px;

        svg {
          margin-right: 6px;
        }

        &:hover {
          color: #1753eb;
        }
      }
    }

    .elv-openApi-integration-table {
      --el-table-border-color: #d4dce5;
      --el-table-border: 1px solid #edf0f3;
      --el-fill-color-lighter: #f9fafb;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #d2dbe4;
      border-radius: 3px;
      border-bottom-width: 1px;

      .el-table__inner-wrapper {
        width: 100%;

        &::after,
        &::before {
          width: 0;
          height: 0;
        }
      }

      &.el-table--border {
        &::after,
        &::before {
          width: 0;
          height: 0;
        }
      }

      .el-table__body-wrapper {
        .el-scrollbar__view {
          margin-bottom: 0px !important;
        }
      }

      .el-table__border-left-patch {
        width: 0;
      }

      .elv-openApi-integration-table-header {
        background: #fff;

        .elv-openApi-integration-table-header__cell {
          background: #fff !important;
          height: 23px;
          box-sizing: border-box;
          border-right-color: #edf0f3;
          border-bottom-color: #edf0f3;
          padding: 0px;

          &:last-child {
            border-right: 0px;
          }

          &.is-center {
            .cell {
              justify-content: center;
            }
          }

          &.is-right .cell {
            justify-content: flex-end;
          }

          .cell {
            color: #b2bacc;
            font-family: 'Plus Jakarta Sans';
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-items: center;
          }
        }
      }

      .el-table__row.elv-openApi-integration-table-row:last-of-type {
        .elv-openApi-integration-table-row__cell.el-table__cell {
          border-bottom: 0px;
        }
      }

      .elv-openApi-integration-table-row__cell {
        padding: 0;
        height: 42px;
        cursor: pointer;

        &:last-child {
          border-right: 0px;
        }

        &.is-right .cell {
          text-align: right;
        }

        &.is-center .cell {
          text-align: center;
        }

        &.is-number .cell {
          font-family: 'Barlow';
          font-weight: 500;
        }

        .cell {
          padding: 0 10px;
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          color: #0e0f11;
          line-height: 16px;
        }

        .elv-openApi-integration-table-row__cell-key {
          display: flex;
          align-items: center;
          color: #1e2024;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          img {
            width: 10px;
            height: 10px;
            display: block;
            margin-left: 4px;
            cursor: pointer;
          }
        }

        .elv-valuation-record-cell__status {
          display: flex;
          height: 22px;
          width: fit-content;
          padding: 0px 8px;
          box-sizing: border-box;
          align-items: center;
          gap: 4px;
          border-radius: 14px;
          border: 1px solid #d0d4d9;
          background: #edf0f3;
          color: #636b75;

          svg {
            margin-right: 4px;
          }

          &.is-approved {
            border-color: #a9dc78;
            background: #f3fceb;
            color: #50970b;
          }
        }

        .elv-openApi-integration-table-row__cell-button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            cursor: pointer;
            fill: #838d95;

            &.is-disabled {
              cursor: not-allowed !important;
              fill: #edf0f3 !important;
            }

            &:first-of-type {
              margin-right: 8px;
            }

            &:hover {
              fill: #1e2024;
            }
          }
        }
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-openApi-integration-confirm-delete.elv-confirm-info-dialog {
  .el-dialog__header {
    justify-content: center;
  }

  .el-dialog__body {
    padding: 24px 48px;

    span {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
</style>
