<template>
  <el-dialog
    v-model="showJournalIntegrationDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-journal-integration-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-journal-integration-dialog-header__title">
        {{ t('common.integration') }}
      </h4>
      <span v-if="isTrial" class="elv-journal-integration-dialog-header__trial">{{ t('common.trial') }}</span>
    </template>
    <el-scrollbar v-loading="dialogLoading" max-height="calc(100vh - 240px)" class="elv-journal-integration-content">
      <div v-if="!showAddDataName && !businessDataTypeList.length" class="elv-journal-integration-empty">
        <svgIcon name="document-unknown" width="24" height="24" fill="#DDE1E6" />
        {{ t('message.noIntegrationsAdded') }}
      </div>

      <ul v-if="businessDataTypeList.length" class="elv-journal-integration-list">
        <li v-for="(item, index) in businessDataTypeList" :key="index" class="elv-journal-integration-item">
          <div class="elv-journal-integration-item__header">
            <div v-if="!item.isEditDataName" class="elv-journal-integration-item__header-info">
              <div class="elv-journal-integration-item__header-info-left">
                <p>{{ item.name }}</p>
              </div>
              <div class="elv-journal-integration-item__header-info-right">
                <p v-if="item?.status === 'DELETING'" class="elv-journal-integration-item__deleting">
                  <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />{{ t('report.deleting') }}
                </p>
                <template v-else>
                  <svgIcon name="sources-edit" width="18" height="18" @click="onEditDataName(index)" />
                  <svgIcon name="sources-delete" width="18" height="18" @click="onDeleteIntegration(item)" />
                </template>
              </div>
            </div>

            <DataNameForm
              v-else
              v-model="item.name"
              :business-data-type-id="item.businessDataTypeId"
              @onResetData="fetchBusinessDataType"
              @onCancelSave="onCancelSave"
            />
          </div>
          <ol v-if="item?.businessDataSourceList.length" class="elv-journal-integration-item__source-list">
            <li
              v-for="(businessDataSource, i) in item?.businessDataSourceList"
              :key="i"
              class="elv-journal-integration-item__source-item"
            >
              <div class="elv-journal-integration-item__source-item-left">
                <div class="elv-journal-integration-item__source-item-left__title">
                  {{ businessDataSource.name }}:&nbsp;<span
                    @click="
                      onViewOriginBusinessDataSource({
                        name: item.nameLowerCase,
                        columns: item?.columns,
                        businessDataSourceId: businessDataSource.businessDataSourceId
                      })
                    "
                    >{{ businessDataSource.count }}</span
                  >
                  <p :class="{ 'is-openApi': businessDataSource.type !== 'CSV' }">
                    {{ businessDataSource.type === 'CSV' ? 'CSV' : 'OpenAPI' }}
                  </p>
                </div>
                <p class="elv-journal-integration-item__source-item-left__info">
                  <span v-if="businessDataSource.type === 'CSV'">{{
                    businessDataSource?.businessDataFile?.originalName
                  }}</span
                  ><template v-else>{{ t('common.syncedOn') }}: </template>
                  {{
                    dayjs
                      .tz(businessDataSource.createdAt, entityStore.entityDetail?.timezone)
                      .format('YYYY/MM/DD HH:mm:ss')
                  }}
                  &nbsp;{{ currentEntityTimezone }}
                </p>
              </div>
              <p
                v-if="businessDataSource?.status === 'DELETING'"
                class="elv-journal-integration-item__source-item-deleting"
              >
                <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />{{ t('report.deleting') }}
              </p>
              <svgIcon
                v-else
                name="sources-delete"
                width="18"
                height="18"
                class="elv-journal-integration-item__source-item-right"
                :class="{ 'is-disabled': item?.status === 'DELETING' }"
                @click="onDeleteSource(businessDataSource, item?.status)"
              />
            </li>
          </ol>

          <el-dropdown
            v-if="item?.status !== 'DELETING'"
            trigger="click"
            placement="bottom-start"
            popper-class="elv-journal-integration-item__source-item__operating-popover"
          >
            <div class="elv-journal-integration-item__button">
              <svgIcon name="add-default" width="16" height="16" fill="#1753EB" />{{
                t('button.addCustomButton', { name: item.nameLowerCase })
              }}
              data
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="
                    onAddBusinessDataSource(index, {
                      businessDataTypeId: item.businessDataTypeId,
                      name: item.nameLowerCase
                    })
                  "
                  >{{ t('button.CSVUpload') }}</el-dropdown-item
                >
                <el-dropdown-item @click="onOpenOpenApiIntegrationDialog">{{
                  t('button.openAPIIntegration')
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
      </ul>

      <DataNameForm
        v-if="showAddDataName"
        v-model="newDataName"
        class="elv-journal-integration-dataName-container"
        @onResetData="fetchBusinessDataType"
        @onCancelSave="onCancelSave"
      />
    </el-scrollbar>

    <template #footer>
      <elv-button height="32" width="452" @click="onAddNewIntegration">
        <svgIcon name="add-default" width="16" height="16" />{{ t('button.addNewIntegration') }}</elv-button
      >
    </template>
  </el-dialog>

  <DeleteBusinessDataDialog
    ref="deleteBusinessDataDialogRef"
    :type="deleteBusinessDataType"
    :currentData="businessDataCurrentData"
    @onCheckDialog="onCheckJournalIntegrationDialog"
    @onResetData="onResetData"
  />

  <UploadBusinessDataCSVDialog
    ref="uploadBusinessDataCSVDialogRef"
    v-model="showUploadCSVFormDialog"
    :currentData="businessDataTypeCurrentData"
    @onResetData="fetchBusinessDataType"
  />

  <OriginBusinessDataDialog v-model="showOriginBusinessDataDialog" :currentData="businessDataCurrentData" />

  <OpenApiIntegrationDialog v-model="showOpenApiIntegrationDataDialog" />
  <LimitReachedDialog ref="limitReachedDialogRef" @onConfirmUpgradeProjectPlan="onConfirmUpgradeProjectPlan" />
  <UpgradeProjectPlanDialog ref="upgradeProjectPlanDialogRef" />
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash-es'
// import { ElMessage } from 'element-plus'
import timezoneList from '@/config/timezone'
import timezone from 'dayjs/plugin/timezone'
import DataNameForm from './DataNameForm.vue'
import BusinessDataApi from '@/api/BusinessDataApi'
import { useGlobalStore } from '@/stores/modules/global'
import { useEntityStore } from '@/stores/modules/entity'
import { useProjectStore } from '@/stores/modules/project'
import OpenApiIntegrationDialog from './OpenApiIntegrationDialog.vue'
import UploadBusinessDataCSVDialog from './UploadBusinessDataCSVDialog.vue'
import { ProjectPermissionLevel, ProjectSubscribeStatus } from '#/ProjectTypes'
import LimitReachedDialog from '@/pages/Financials/components/LimitReachedDialog.vue'
import { BusinessDataSourceItemType, BusinessDataTypeItemType } from '#/BusinessDataTypes'
import DeleteBusinessDataDialog from '../../../../components/BusinessData/DeleteBusinessDataDialog.vue'
import OriginBusinessDataDialog from '../../../../components/BusinessData/OriginBusinessDataDialog.vue'
import UpgradeProjectPlanDialog from '@/pages/Financials/ProjectSetting/components/UpgradeProjectPlanDialog.vue'

const props = defineProps({
  model: {
    type: String,
    default: 'add' // add, edit
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const globalStore = useGlobalStore()
const projectStore = useProjectStore()
// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['onResetList'])

interface businessDataTypeListType extends BusinessDataTypeItemType {
  isEditDataName: boolean
}

const newDataName = ref('')
const dataNameMode = ref('add')
const editDataNameIndex = ref(0)
const originDataName = ref('')
const showAddDataName = ref(false)
const dialogLoading = ref(false)
const deleteBusinessDataType = ref('SOURCE') // SOURCE, TYPE
const uploadBusinessDataCSVDialogRef = ref()
const deleteBusinessDataDialogRef = ref()
const businessDataCurrentData: any = ref({})
const businessDataTypeCurrentData: any = ref({})
const businessDataTypeList = ref<businessDataTypeListType[]>([])
const showJournalIntegrationDialog = ref(false)
const showUploadCSVFormDialog = ref(false)
const showOriginBusinessDataDialog = ref(false)
const showOpenApiIntegrationDataDialog = ref(false)
const limitReachedDialogRef = useTemplateRef('limitReachedDialogRef')
const upgradeProjectPlanDialogRef = useTemplateRef('upgradeProjectPlanDialogRef')

// eslint-disable-next-line no-unused-vars
const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityTimezone = computed(() => {
  return timezoneList.find((item) => item.area === entityStore.entityDetail?.timezone)?.timezone ?? 'UTC+00:00'
})

const projectDetail = computed(() => {
  if (!isEmpty(projectStore.projectDetail)) return projectStore.projectDetail
  return globalStore.projectDetail(entityStore.entityDetail?.projectId)
})

const isTrial = computed(() => {
  return projectDetail.value?.plan === ProjectPermissionLevel.FREE
})

const onConfirmUpgradeProjectPlan = () => {
  upgradeProjectPlanDialogRef.value?.changeUpgradeProjectDialogStatus()
}

const onCheckJournalIntegrationDialog = () => {
  showJournalIntegrationDialog.value = !showJournalIntegrationDialog.value
}

const onCloseDialog = () => {}

const onEditDataName = (index: number) => {
  dataNameMode.value = 'edit'
  editDataNameIndex.value = index
  originDataName.value = businessDataTypeList.value[index].name
  businessDataTypeList.value[index].isEditDataName = true
}

const onAddNewIntegration = () => {
  dataNameMode.value = 'add'
  showAddDataName.value = true
}

const onCancelSave = () => {
  if (dataNameMode.value === 'add') {
    showAddDataName.value = false
    newDataName.value = ''
  } else {
    businessDataTypeList.value[editDataNameIndex.value].isEditDataName = false
    businessDataTypeList.value[editDataNameIndex.value].name = originDataName.value
  }
}

const onViewOriginBusinessDataSource = (row: any) => {
  businessDataCurrentData.value = row
  showOriginBusinessDataDialog.value = true
}

const onOpenOpenApiIntegrationDialog = () => {
  showOpenApiIntegrationDataDialog.value = true
}

const fetchBusinessDataType = async () => {
  try {
    showAddDataName.value = false
    showUploadCSVFormDialog.value = false
    dialogLoading.value = true
    const { data } = await BusinessDataApi.getBusinessDataTypeList(entityId.value)
    businessDataTypeList.value = data.map((item: BusinessDataTypeItemType) => {
      return {
        ...item,
        isEditDataName: false
      }
    })
  } catch (error: any) {
    console.error(error)
  } finally {
    dialogLoading.value = false
  }
}

const onResetData = () => {
  if (!showJournalIntegrationDialog.value) {
    showJournalIntegrationDialog.value = true
    onCheckJournalIntegrationDialog()
  }
  // fetchBusinessDataType()
}

const onDeleteSource = (row: BusinessDataSourceItemType, status: string) => {
  if (status === 'DELETING') return
  deleteBusinessDataType.value = 'SOURCE'
  businessDataCurrentData.value = row
  onCheckJournalIntegrationDialog()
  deleteBusinessDataDialogRef.value.onCheckDeleteDialog()
}

const onDeleteIntegration = (row: BusinessDataTypeItemType) => {
  deleteBusinessDataType.value = 'TYPE'
  businessDataCurrentData.value = row
  onCheckJournalIntegrationDialog()
  deleteBusinessDataDialogRef.value.onCheckDeleteDialog()
}

const onAddBusinessDataSource = (index: number, row: any) => {
  if (
    projectDetail.value?.subscribeStatus === ProjectSubscribeStatus.EXCEEDED ||
    projectDetail.value?.txCountStatus === ProjectSubscribeStatus.EXCEEDED
  ) {
    limitReachedDialogRef.value?.changeLimitReachedDialogStatus()
    return
  }
  businessDataTypeCurrentData.value = row
  showUploadCSVFormDialog.value = true
}

defineExpose({ onCheckJournalIntegrationDialog })

watch(
  [() => showJournalIntegrationDialog.value, () => props.model],
  async () => {
    if (showJournalIntegrationDialog.value) {
      await fetchBusinessDataType()
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-journal-integration-dialog {
  width: 500px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 0px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-journal-integration-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }

    .elv-journal-integration-dialog-header__trial {
      width: 31px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #edf0f3;
      border-radius: 4px;
      border: 0.5px solid #d0d4d9;
      font-size: 11px;
      font-weight: 400;
      margin-left: 8px;
      color: #636b75;
    }
  }

  .el-dialog__body {
    padding: 18px 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-journal-integration-content {
      width: 100%;

      .el-scrollbar__view {
        width: 100%;
        padding: 0px 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .elv-journal-integration-empty {
        width: 100%;
        padding: 8px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #aaafb6;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // margin: 0 auto;
        svg {
          margin-bottom: 8px;
        }
      }
    }

    .elv-journal-integration-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .elv-journal-integration-item {
      display: flex;
      padding: 20px;
      width: 100%;
      box-sizing: border-box;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 8px;
      align-self: stretch;
      background: #f9fafb;

      .elv-journal-integration-item__header {
        width: 100%;
        padding-bottom: 10px;
      }

      .elv-journal-integration-item__header-info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .elv-journal-integration-item__header-info-left {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          p {
            color: #000;
            font-family: 'Plus Jakarta Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        .elv-journal-integration-item__header-info-right {
          display: flex;
          align-items: center;
          gap: 8px;

          svg {
            cursor: pointer;
            fill: #838d95;

            &:hover {
              fill: #1753eb;
            }
          }

          .elv-journal-integration-item__deleting {
            display: flex;
            align-items: center;

            img {
              width: 14px;
              height: 14px;
              display: block;
              margin-right: 6px;
              animation: loading-rotate 2s linear infinite;
            }
          }
        }
      }

      .elv-journal-integration-item__source-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        border-top: 1px solid #edf0f3;
      }

      .elv-journal-integration-item__source-item {
        height: 49px;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 8px 0px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid #edf0f3;

        .elv-journal-integration-item__source-item-left__title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 4px;

          span {
            cursor: pointer;
            font-family: 'Barlow';
            font-weight: 500;
            text-decoration-line: underline;
          }

          p {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 16px;
            box-sizing: border-box;
            border-radius: 2px;
            border: 1px solid #ff9777;
            color: #ff9777;
            leading-trim: both;
            text-edge: cap;
            font-family: 'Barlow';
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-left: 4px;

            &.is-openApi {
              width: 52px;
              color: #7596eb;
              border-color: #7596eb;
            }
          }
        }

        .elv-journal-integration-item__source-item-left__info {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #aaafb6;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 11px;
            margin-right: 4px;
            padding-right: 4px;
            border-right: 1px solid #aaafb6;
          }
        }

        .elv-journal-integration-item__source-item-right {
          cursor: pointer;
          fill: #838d95;

          &:not(.is-disabled):hover {
            fill: #1753eb;
          }

          &.is-disabled {
            fill: #aaafb6;
            cursor: not-allowed;
          }
        }

        .elv-journal-integration-item__source-item-deleting {
          display: flex;
          align-items: center;

          img {
            width: 14px;
            height: 14px;
            display: block;
            margin-right: 6px;
            animation: loading-rotate 2s linear infinite;
          }
        }
      }

      .elv-journal-integration-item__button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #1753eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        svg {
          margin-right: 4px;
        }
      }
    }

    .elv-journal-integration-dataName-container {
      padding: 20px;
      min-height: 160px;
    }
  }

  .el-dialog__footer {
    padding: 10px 0px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button.elv-button {
      span {
        color: #1753eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        svg {
          margin-right: 4px;
          fill: #1753eb;
        }
      }
    }
  }
}

.el-popper.elv-journal-integration-item__source-item__operating-popover {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: auto !important;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow:
    0px 8px 16px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  .el-scrollbar__view,
  .el-scrollbar {
    width: 100%;
  }

  .el-dropdown-menu {
    padding: 0px;
  }

  li {
    min-width: 160px;
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #1e2024;

    &:hover {
      background: #f9fafb;
      color: #1753eb;
    }

    img {
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 10px;
    }

    svg {
      margin-right: 10px;
      fill: #5e85eb;
    }
  }
}
</style>
